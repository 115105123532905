import { Badge } from "primereact/badge";
import ElapsedTime from "./ElapsedTime";
import { useEffect, useState } from "react";
import { ProgressBar } from "primereact/progressbar";

const Card = ({ item }) => {
  const [progress2Mode, setProgressMode] = useState("indeterminate");

  useEffect(() => {
    const interval = setInterval(() => {
      setProgressMode((prevMode) => {
        const newMode =
          prevMode === "determinate" ? "indeterminate" : "determinate";
        console.log(`Changing progress mode to: ${newMode}`);
        return newMode;
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const progressValue =
    item.istasyon === "HAMACMA"
      ? ((item.message.okutulan / item.message.toplamtop) * 100).toFixed(0)
      : item.istasyon === "KALITEKONTROL"
      ? ((item.message.hazirKg / item.message.kg) * 100).toFixed(0)
      : 0;

  const progressMode = progressValue >= 100 ? "determinate" : progress2Mode;

  return (
    <div className=" bg-white border-round-lg shadow-4    ">
      {item.message.statu === "online" ? (
        <div className="p-overlay-badge">
          <Badge severity="success" value={" "} />
        </div>
      ) : (
        <div className="p-overlay-badge">
          <Badge severity="danger" value={" "} />
        </div>
      )}
      <div className="text-center border-round-top-lg bg-blue-200 p-1">
        <span className="font-bold">{item.makine}</span>
      </div>
      {item.message.statu === "online" && item.message.saat ? (
        <div>
          {item.message.tarih ? (
            <div
              className="flex justify-content-evenly  
            shadow-5 m-1 p-1 text-center  p-card  flex-wrap"
            >
              <span>{item.message.tarih}</span>
              <span>
                <strong> / </strong>
              </span>
              <span>
                {item.message.saat} --{" "}
                <strong>
                  <ElapsedTime
                    tarih={item.message.tarih}
                    saat={item.message.saat}
                  />
                </strong>
              </span>
            </div>
          ) : (
            <span>
              {item.message.personel} /<strong> Giriş Yaptı</strong>
            </span>
          )}

          <div
            className={`flex-column flex ${
              item.istasyon === "JET" ? "h-8rem" : "h-11rem"
            }`}
          >
            <div className="p-card shadow-5 m-1 p-1 text-center flex-grow-1 flex-column flex">
              <span className="flex-grow-1">
                <strong>{item.message.firma}</strong> - {item.message.urun} -
                <strong> {item.message.parti}</strong> <br />
                
              </span>
              <div className="flex-grow-1 flex justify-content-evenly   flex-wrap">
                <span>
                  {" "}
                  {Math.round(item.message.mt)} <strong>Mt.</strong>
                </span>
                <span>
                  {" "}
                  {Math.round(item.message.kg)} <strong>Kg.</strong>
                </span>
                {item.istasyon != "RAM" && item.istasyon != "JET" ? (
                  <span>
                    {" "}
                    {Math.round(item.message.top)} <strong>Top</strong>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="p-card shadow-5 m-1 p-1">
              <span className="justify-content-center flex">
                <i className="pi pi-user"></i>
                {" " + item.message.personel}
              </span>
            </div>  
            {item.istasyon !== "RAM" ? (
             <div className="p-card shadow-5 m-1 p-1">
             <span className="justify-content-center flex">
             {item.message.tanim} 
                   <i
                     className="pi pi-spin pi-cog"
                     style={{ fontSize: "1rem" }}
                   />
             </span>
           </div>
            ) : null}
            
            {item.istasyon === "RAM" ? (
              <>
                <div className="p-card shadow-5  m-1 p-1 text-center">
                  <i
                    className="pi pi-spin pi-cog"
                    style={{ fontSize: "1rem" }}
                  ></i>
                  <span>
                    {item.message.renkKodu} {item.message.renkAdi}
                    {item.message.ekislem}
                  </span>
                </div>
                <div>
                  {item.message.ramHizi ||
                  item.message.ramEn ||
                  item.message.ramMetre ? (
                    <div className="p-card shadow-5  m-1 p-1 text-center flex justify-content-around  flex-wrap ">
                      {item.message.ramHizi ? (
                        <span>
                          <strong>Hız:</strong>
                          <span> {item.message.ramHizi}</span>
                        </span>
                      ) : (
                        <></>
                      )}
                      {item.message.ramEn ? (
                        <span>
                          <strong>En:</strong>
                          <span> {item.message.ramEn}</span>
                        </span>
                      ) : (
                        <></>
                      )}
                      {item.message.ramMetre ? (
                        <span>
                          <strong>Metre:</strong>
                          <span> {item.message.ramMetre}</span>
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <div className="p-card m-1 p-1 text-center">
                      <strong>(Hız - Metre - En) Girilmemiş</strong>
                    </div>
                  )}
                </div>
              </>
            ) : null}
            {item.istasyon === "HAMACMA" ? (
              <>
                <div className="p-card shadow-5 m-1 text-center">
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      textAlign: "right",
                    }}
                  >
                    <ProgressBar
                      mode={progressMode}
                      value={progressValue}
                      className="bg-green-400"
                      color="green"
                      showValue={false}
                      style={{
                        height: "30px",
                        backgroundColor: "white",
                      }}
                    ></ProgressBar>
                    <div
                      className="absolute top-0 left-0 w-full h-full flex align-items-center justify-content-end pr-3"
                      style={{
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      {item.message.okutulan}/{item.message.toplamtop}
                    </div>
                    <div
                      className="absolute top-0 right-0 w-full h-full flex align-items-center justify-content-start pl-3"
                      style={{
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      {progressValue + "%"}
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {item.istasyon === "KALITEKONTROL" ? (
              <>
                <div className="p-card  shadow-5 m-1   text-center">
                  <div className="relative w-full text-right	">
                    <ProgressBar
                      mode={progressMode}
                      value={progressValue}
                      className="bg-green-400 	"
                      color="green"
                      showValue={false}
                      style={{
                        height: "30px",
                        backgroundColor: "white",
                      }}
                    ></ProgressBar>
                    <div
                      className="absolute top-0 left-0 w-full h-full flex align-items-center justify-content-end pr-3"
                      style={{
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      {item.message.hazirKg}/{item.message.kg}
                    </div>
                    <div
                      className="absolute top-0 right-0 w-full h-full flex align-items-center justify-content-start pl-3"
                      style={{
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      {progressValue + "%"}
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="flex-column flex p-1  surface-200 p-card ">
          <div
            className={`mt-1 mb-1 text-center      align-content-center text-xl ${
              item.istasyon === "JET" ? "h-9rem" : "h-12rem"
            }`}
          >
            <strong>Makine Çevrimdışı</strong>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
