// src/components/Dashboard.js
import React, { useState, useEffect } from "react";
import mqtt from "mqtt";
import { Toolbar } from "primereact/toolbar";
import logo from "../assets/logo2.png";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import Card from "./Card";

const Dashboard = ({ onLogout }) => {
  const [messages, setMessages] = useState([]);
  const [client, setClient] = useState(null);

  useEffect(() => {
    
    const url = 'wss://178.32.143.20:8084'
    const options = {
      // Clean session
      clean: true,
      connectTimeout: 4000,
      // Authentication
      username: "isletme",
      password: "Asdasd123",
      clientId: "flutter_client",
      rejectUnauthorized:false,
    }
    const client  = mqtt.connect(url, options)
    setClient(client);

    client.on("connect", () => {
      console.log("Connected to MQTT broker");
      client.subscribe("#");
    });

    client.on("message", (topic, message) => {
      try {
        console.log(
          `Topic: ${topic}, Message messageeeeeee:`,
          message.toString()
        );
        const parsedMessage = JSON.parse(message.toString());
        //console.log(`Topic: ${topic}, Message:`, parsedMessage);

        const [istasyon, makine] = topic.split("/");
        setMessages((prevMessages) => {
          // Mevcut mesajlar arasında güncelleme yap
          const index = prevMessages.findIndex(
            (m) => m.istasyon === istasyon && m.makine === makine
          );
          if (index !== -1) {
            // Eğer makine mevcutsa, mesajı güncelle
            const updatedMessages = [...prevMessages];
            updatedMessages[index] = {
              istasyon,
              makine,
              message: parsedMessage,
            };
            return updatedMessages;
          } else {
            // Eğer makine mevcut değilse, yeni mesajı ekle
            return [
              ...prevMessages,
              { istasyon, makine, message: parsedMessage },
            ];
          }
        });
      } catch (error) {
        console.error("Error parsing message: ", error);
      }
    });

    client.on("error", (err) => {
      console.error("MQTT connection error: ", err);
    });

    return () => {
      client.end();
    };
  }, []);
  const startContent = (
    <React.Fragment>
      <img src={logo} alt="Logo" className="w-5rem md:w-7rem" />
    </React.Fragment>
  );

  const jetSort = (a, b) => {
    const groupOrder = ["can", "ktm", "ataç"];

    const getGroupIndex = (makine) => {
      for (let i = 0; i < groupOrder.length; i++) {
        if (makine.toLowerCase().startsWith(groupOrder[i])) {
          return i;
        }
      }
      return groupOrder.length; // Eğer hiçbir grup eşleşmiyorsa, en sona koy
    };

    const groupA = getGroupIndex(a.makine);
    const groupB = getGroupIndex(b.makine);

    if (groupA === groupB) {
      // Aynı grupta iseler, sayısal olarak sıralama yap
      return a.makine.localeCompare(b.makine, undefined, { numeric: true });
    }

    // Farklı gruplardalarsa, grup sırasına göre sıralama yap
    return groupA - groupB;
  };

  const centerContent = (
    <div>
      <h5 className="text-primary">
        {messages.length > 0 ? "İşletme Faaliyet Ekranı" : "Veriler Alınıyor"}
      </h5>
    </div>
  );

  const endContent = (
    <React.Fragment>
      <Button
        icon="pi pi-sign-out"
        text
        severity="danger"
        onClick={onLogout}
        tooltip="Çıkış Yap"
      />
    </React.Fragment>
  );

  return (
    <div className="  p-2  min-h-screen bg-primary-200">
      <div className=" ">
        <Toolbar
          start={startContent}
          center={centerContent}
          end={endContent}
          className=" bg-white shadow-6 p-2  "
        />
      </div>

      {messages.length > 0 ? (
        <div className="grid mt-1    ">
          <div className=" col-12    ">
            <div className=" surface-ground shadow-4  border-round-lg   ">
              <div className="text-center  border-round-top-lg bg-blue-100 p-1">
                <span className="font-bold">RAM</span>
              </div>
              <div className="grid  p-2">
                {messages.sort(jetSort).map((item, index) =>
                  item.istasyon == "RAM" ? (
                    <div
                      className="col-12 sm:col-6  md:col-4 lg:col-3 xl:col-3"
                      key={index}
                    >
                      <Card item={item} />
                    </div>
                  ) : null
                )}
              </div>
            </div>
          </div>
          <div className=" col-12    ">
            <div className=" surface-ground shadow-4  border-round-lg   ">
              <div className="text-center  border-round-top-lg bg-blue-100 p-1">
                <span className="font-bold">JET</span>
              </div>
              <div className="grid  p-2">
                {messages
                  .sort((a, b) => a.makine.localeCompare(b.makine))
                  .map((item, index) =>
                    item.istasyon == "JET" ? (
                      <div
                        className="col-12 sm:col-6  md:col-4 lg:col-3 xl:col-2"
                        key={index}
                      >
                        <Card item={item} />
                      </div>
                    ) : null
                  )}
              </div>
            </div>
          </div>

          <div className=" col-12    ">
            <div className=" surface-ground shadow-4  border-round-lg   ">
              <div className="text-center  border-round-top-lg bg-blue-100 p-1">
                <span className="font-bold">KALİTE KONTROL</span>
              </div>
              <div className="grid  p-2">
                {messages
                  .sort((a, b) => a.makine.localeCompare(b.makine))
                  .map((item, index) => {
                    return item.istasyon == "KALITEKONTROL" ? (
                      <div
                        className="col-12 sm:col-6  md:col-4 lg:col-3 xl:col-2"
                        key={index}
                      >
                        <Card item={item} />
                      </div>
                    ) : null;
                  })}
              </div>
            </div>
          </div>
          <div className=" col-12    ">
            <div className=" surface-ground shadow-4  border-round-lg   ">
              <div className="text-center  border-round-top-lg bg-blue-100 p-1">
                <span className="font-bold">HAM AÇMA</span>
              </div>
              <div className="grid  p-2">
                {messages
                  .sort((a, b) => a.makine.localeCompare(b.makine))
                  .map((item, index) => {
                    return item.istasyon == "HAMACMA" ? (
                      <div
                        className="col-12 sm:col-6  md:col-4 lg:col-3 xl:col-2"
                        key={index}
                      >
                        <Card item={item} />
                      </div>
                    ) : null;
                  })}
              </div>
            </div>
          </div>

          <div className=" col-12 md:col-4 ">
            <div className="grid justify-content-center p-2">
              {messages.map((item, index) =>
                item.istasyon == "ETKİNYIKAMA" ? (
                  <div className="col-12 " key={index}>
                    <Card item={item} />
                  </div>
                ) : null
              )}
            </div>
          </div>
          <div className=" col-12 md:col-4 ">
            <div className="grid justify-content-center p-2">
              {messages.map((item, index) =>
                item.istasyon == "CONTINUYIKAMA" ? (
                  <div className="col-12 " key={index}>
                    <Card item={item} />
                  </div>
                ) : null
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ height: "50vh" }}
          className=" flex  justify-content-center align-content-center align-items-center text-center"
        >
          <ProgressSpinner />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
