import { useEffect, useState } from "react";

const ElapsedTime = ({ tarih, saat }) => {
  const [elapsedTime, setElapsedTime] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      
      // Tarih ve saat formatını ayrıştırma
      const [year, month, day] = tarih.split('-').map(Number);
      const [hours, minutes, seconds] = saat.split(':').map(Number);
      
      const messageTime = new Date(year, month - 1, day, hours, minutes, seconds); // JavaScript'te aylar 0'dan başlar, bu yüzden month-1 yapılır.
      
      const timeDiff = Math.floor((currentTime - messageTime) / 1000); // Geçen süreyi saniye cinsinden hesapla

      // Geçen süreyi formatlayın
      const elapsedHours = Math.floor(timeDiff / 3600);
      const elapsedMinutes = Math.floor((timeDiff % 3600) / 60);
      const elapsedSeconds = timeDiff % 60;

      setElapsedTime(`${elapsedHours}:${elapsedMinutes}:${elapsedSeconds}`);
    
    }, 1000);

    return () => clearInterval(interval); // Bileşen unmount olduğunda interval'i temizle
  }, [tarih, saat]);

  return <>{elapsedTime}</>;
};

export default ElapsedTime;
