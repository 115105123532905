import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App"; 
import 'primereact/resources/themes/lara-light-teal/theme.css';  
import 'primereact/resources/primereact.min.css';           
import 'primeicons/primeicons.css';                         
import 'primeflex/primeflex.css';                      
import { PrimeReactProvider } from "primereact/api";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PrimeReactProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </PrimeReactProvider>
);
 