 
import React, { useState } from "react";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import "primeflex/primeflex.css";
import logo from "../assets/logo2.png"; 
import { FloatLabel } from "primereact/floatlabel";

const Login = ({ onLogin }) => {
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin(password);
  };

  return (
    <div className="flex justify-content-center align-items-center min-h-screen bg-primary-200">
      <Card className="p-1 flex  align-items-center m-3 text-center  ">
        <img src={logo} alt="Logo" className="w-5" />
<h5>Boyer İşletme İzle</h5>
        <form onSubmit={handleSubmit} className="  ">
         
            <div className="  pt-3  ">
              <FloatLabel>
                <Password
                  id="password"
                  inputStyle={{ width: "100%" }}
                  style={{ width: "100%" }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  feedback={false}
                  toggleMask
                  inputClassName="w-full"
                  className="  w-full"
                  pt={{ iconField: { root: { className: "w-full" } } }}
                />
                <label htmlFor="password">Şifre</label>
              </FloatLabel>
            </div>
           
          <div className="pt-5 pb-5 ">
            <Button
              type="submit"
              className="w-full"
              label="Giriş Yap"
              icon="pi pi-angle-right"
              iconPos="right"
            />
          </div>
        </form>
      </Card>
    </div>
  );
};

export default Login;
