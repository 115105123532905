// src/App.js
import React, { useEffect, useRef, useState } from 'react';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import { Toast } from "primereact/toast";
function App() {
  const toast = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const show = (sev,baslik,mesaj) => {
    toast.current.show({ severity: sev, summary: baslik, detail: mesaj });
};
  const handleLogin = (password) => {
  if(password.length >0) {
     if (password === 'nass') {
      setIsLoggedIn(true);
      localStorage.setItem('password', password);
    } else {
     show("error","Hatalı Giriş","Lütfen şifrenizi kontrol edip tekrar deneyin.")
    }
  }else{
    show("info","Eksik Şifre","Lütfen şifrenizi giriniz.")

  }
   
  };
  useEffect(() => {
    const storedPassword = localStorage.getItem('password');
    if (storedPassword === 'nass') {
      setIsLoggedIn(true);
    }
  }, []);
  const handleLogout = () => {
    localStorage.removeItem('password');
    setIsLoggedIn(false);
  };

  return (
    <div className="App">
        <Toast ref={toast} />
      {isLoggedIn ? (
       <Dashboard onLogout={handleLogout} />
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;
